import { DrupalJsonApiParams } from "drupal-jsonapi-params"

export function getParams(resourceType:  string,
        mode: string = null, query: boolean = true) {
    /*const apiParams = new DrupalJsonApiParams().addFilter(
        "field_site.meta.drupal_internal__target_id",
        process.env.DRUPAL_SITE_ID
    )*/
    const apiParams = new DrupalJsonApiParams();

    let name = mode ? `${resourceType}--${mode}` : resourceType;

    if (name === "node--page") {
        apiParams.addInclude(["field_paragraphs"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_paragraphs",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);

    }

    if(name === "node--tendr"){
        apiParams.addInclude(["field_campaigns","field_campaigns.field_tags"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "field_campaigns",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("node--campaign", ["field_tags"]);
        apiParams.addFields("taxonomy_term--tags", ["name","path"]);
    }

    if(name === "node--studie"){
        apiParams.addInclude(["field_logo","field_ogilvy_logo.field_media_image","field_image","uid","field_file","field_file_2"]);
        //apiParams.addInclude(["field_logo","field_image"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_image",
            "field_logo",
            "field_ogilvy_logo",
            "field_file",
            "field_file_2",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    if(name === "node--campaign") {
        apiParams.addInclude(["field_hp_image.field_media_image","field_image", "uid","field_video","field_expertize","field_paragraphs"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_image",
            "field_hp_image",
            "status",
            "metatag",
            "field_video",
            "field_client",
            "field_expertize",
            "field_paragraphs",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    if(name === "node--campaign--teaser") {
        apiParams.addInclude(["field_hp_image.field_media_image","field_expertize"]);
        apiParams.addFields(resourceType, [
            "title",
            "field_hp_image",
            "field_client",
            "path",
            "status",
            "promote",
            "metatag",
            "field_promote_to_all_campaigns",
            "field_expertize",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    if(name === "node--campaign--slider") {
        apiParams.addInclude(["field_hp_image.field_media_image","field_expertize"]);
        apiParams.addFields(resourceType, [
            "title",
            "field_hp_image",
            "field_client",
            "path",
            "status",
            "promote",
            "metatag",
            "field_promote_to_all_campaigns",
            "field_expertize",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    if(name === "node--campaign--tender") {
        apiParams.addInclude(["field_image", "uid","field_video","field_tags","field_paragraphs"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_image",
            "status",
            "metatag",
            "field_video",
            "field_client",
            "drupal_internal__nid",
            "field_tags",
            "field_paragraphs",
            "field_year"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    if (name === "node--article") {
        apiParams.addInclude(["field_image", "uid", "field_paragraphs", "field_paragraphs.field_image", "field_color"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_image",
            "field_color",
            "field_paragraphs",
            "field_perex",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("taxonomy_term--color", ["name","path","field_color"]);
        apiParams.addFields("media--remote_video",["field_media_oembed_video"])
        //apiParams.addFields("taxonomy_term--news_category", ["name","path"]);

    }

    if(name === "node--article--teaser") {
        apiParams.addInclude(["field_color"]);
        apiParams.addFields(resourceType,[
            "title",
            "field_short_perex",
            "path",
            "created",
            "field_color",
            "drupal_internal__nid"
        ]);
        //apiParams.addFields("taxonomy_term--color", ["name","path","field_color"]);
    }

    if(name === "node--article--count") {
        apiParams.addFields(resourceType,[
            "field_color",
            "drupal_internal__nid"
        ]);
    }

    if (name === "node--page_ogilvy_performance") {
        apiParams.addInclude(["field_paragraphs"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_paragraphs",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);
    }

    if (name === "node--article_ogilvy_performance") {
        apiParams.addInclude(["field_image", "uid", "field_paragraphs", "field_paragraphs.field_image"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_image",
            "field_paragraphs",
            "field_perex",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);
        //apiParams.addFields("media--remote_video",["field_media_oembed_video"])
        //apiParams.addFields("taxonomy_term--news_category", ["name","path"]);

    }

    if(name === "node--article_ogilvy_performance--teaser") {
        //apiParams.addInclude(["field_color"]);
        apiParams.addFields(resourceType,[
            "title",
            "field_short_perex",
            "path",
            "created",
            "drupal_internal__nid"
        ]);
        //apiParams.addFields("taxonomy_term--color", ["name","path","field_color"]);
    }

    if(name === "node--microsite") {
        apiParams.addInclude(["field_images", "uid","field_css_files","field_form"]);
        apiParams.addFields(resourceType, [
            "title",
            "body",
            "uid",
            "created",
            "field_css_files",
            "field_css_external_files",
            "field_js_external_files",
            "field_js_script",
            "field_form",
            "field_images",
            "field_directory",
            "status",
            "metatag",
            "drupal_internal__nid"
        ]);
        apiParams.addFields("media--image", ["field_media_image"]);
        apiParams.addFields("file--file", ["uri", "resourceIdObjMeta","image_style_uri"]);
    }

    return (query ? apiParams.getQueryObject() : apiParams )
}
